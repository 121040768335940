<template>
  <NuxtLayout :header-links="headerLinks">
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>

/*
  Initialize dependencies and variables
*/
const route = useRoute();

/*
  Get the current locale and language
*/
const { locale: currentLocale, defaultLocale, t: translationObj } = useI18n();
const language = ref('');

/*
  Get the current path and remove the language prefix
*/
const replacedPath = ref('');
const path = ref('');

/*
  Prepare the meta data used for the request
*/
const prepareMetaData = () => {
    language.value = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;
    replacedPath.value = route.path.replace(`/${language.value}`, '');
    path.value = replacedPath.value === '/' || replacedPath.value === '' ? '/home' : replacedPath.value;
};
prepareMetaData();

/*
  Watch the route path and remove the language prefix
*/
watch(() => route.path, () => {
    prepareMetaData();
});

const { openPopup } = useConfirmPopupStore();
watch(() => route.path, (newPath) => {
    if (newPath === '/dashboard' && route.query?.setupComplete === 'true') {
        openPopup({
            contentTitle: translationObj('welcomeConfirm.title'),
            contentText: translationObj('welcomeConfirm.text'),
            confirmText: translationObj('welcomeConfirm.confirmButton'),
            cancelText: '',
            showConfirm: true,
            showCancel: false,
            onCancel: null,
            onConfirm: null,
        });
    }
});

const { getNavigationLinks } = useSettingsStore();

/*
  Computed data from api
*/
const headerLinks = getNavigationLinks;
</script>
<style lang="scss">
@import './assets/scss/resets';
@import './assets/scss/root';
@import './assets/scss/transitions';

.page-enter-active,
.page-leave-active {
    transition: all 0.2s;
}

.page-leave-to {
    opacity: 0;
}

.page-enter-from {
    opacity: 0;
}
</style>
