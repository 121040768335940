import revive_payload_client_GIcDZuPXp6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.18.5_eslint@8.51.0_rollup@4.20.0_sass@1.69.3_stylelint@14.16.1_typ_laqnneb4ut4c524icfryghlzve/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Z5yQtsjCOY from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.18.5_eslint@8.51.0_rollup@4.20.0_sass@1.69.3_stylelint@14.16.1_typ_laqnneb4ut4c524icfryghlzve/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Oq1OZxmf3N from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.18.5_eslint@8.51.0_rollup@4.20.0_sass@1.69.3_stylelint@14.16.1_typ_laqnneb4ut4c524icfryghlzve/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_1yZ4ePTiQO from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.18.5_eslint@8.51.0_rollup@4.20.0_sass@1.69.3_stylelint@14.16.1_typ_laqnneb4ut4c524icfryghlzve/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_QOelU6k1UM from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.18.5_eslint@8.51.0_rollup@4.20.0_sass@1.69.3_stylelint@14.16.1_typ_laqnneb4ut4c524icfryghlzve/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_8eU3armYyb from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.20.0_typescript@4.9.5_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_nTwGeLKphj from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.18.5_eslint@8.51.0_rollup@4.20.0_sass@1.69.3_stylelint@14.16.1_typ_laqnneb4ut4c524icfryghlzve/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_2e1rUMKa7n from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.2_rollup@4.20.0_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_LVnGpVdHkk from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.18.5_eslint@8.51.0_rollup@4.20.0_sass@1.69.3_stylelint@14.16.1_typ_laqnneb4ut4c524icfryghlzve/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import app_init_hybrid_5gOhL5QG1b from "/vercel/path0/plugins/app-init-hybrid.js";
import app_init_client_lDwNIL1JCF from "/vercel/path0/plugins/app-init.client.js";
import auth_ANExhJHvcR from "/vercel/path0/plugins/auth.js";
import maska_r8BHBbSmHt from "/vercel/path0/plugins/maska.js";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import vue_gtag_S6KjvYXIWb from "/vercel/path0/plugins/vue-gtag.js";
export default [
  revive_payload_client_GIcDZuPXp6,
  unhead_Z5yQtsjCOY,
  router_Oq1OZxmf3N,
  payload_client_1yZ4ePTiQO,
  check_outdated_build_client_QOelU6k1UM,
  plugin_vue3_8eU3armYyb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_nTwGeLKphj,
  i18n_2e1rUMKa7n,
  chunk_reload_client_LVnGpVdHkk,
  app_init_hybrid_5gOhL5QG1b,
  app_init_client_lDwNIL1JCF,
  auth_ANExhJHvcR,
  maska_r8BHBbSmHt,
  sentry_client_GoGQuZo4Et,
  vue_gtag_S6KjvYXIWb
]