import dayjs from 'dayjs';
import de from 'dayjs/locale/de';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.locale({
    ...de,
    weekStart: 1,
});

export default () => ({ dayjs });
