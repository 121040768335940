import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.18.5_eslint@8.51.0_rollup@4.20.0_sass@1.69.3_stylelint@14.16.1_typ_laqnneb4ut4c524icfryghlzve/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.18.5_eslint@8.51.0_rollup@4.20.0_sass@1.69.3_stylelint@14.16.1_typ_laqnneb4ut4c524icfryghlzve/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-auth": () => import("/vercel/path0/middleware/auth.auth.js"),
  "auth-guest": () => import("/vercel/path0/middleware/auth.guest.js"),
  "auth-setup": () => import("/vercel/path0/middleware/auth.setup.js"),
  "has-restaurant": () => import("/vercel/path0/middleware/has.restaurant.js"),
  "has-tenant": () => import("/vercel/path0/middleware/has.tenant.js"),
  "query-resetpage": () => import("/vercel/path0/middleware/query.resetpage.js")
}