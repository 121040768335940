const { dayjs } = useDayJs();
export default () => {
    /*
        Checks if a given link is internal or not
        @param url (String) the url you want to check
    */
    const isInternalLink = (url) => {
        if (!url) {
            throw createError({
                statusCode: '500',
                statusMessage: 'Fatal: isInternalLink function expected url string @useHelpers',
            });
        }

        const runtimeConfig = useRuntimeConfig();
        const base = new URL(runtimeConfig.public.BASE_URL);
        return new URL(url, base).hostname === base.hostname && !url.includes('@');
    };

    const urlExists = (url) => {
        const http = new XMLHttpRequest();
        http.open('HEAD', url, true);
        http.send();
        if (http.status !== 404) return true;
        return false;
    };

    const slugify = (str) => str?.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-')
        ?.replace(/^-+|-+$/g, '');

    const removeGetParams = (str) => {
        const splitted = str.split('?');
        return splitted[0];
    };

    const deepObjectLog = (obj) => {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify({
            obj,
        }, null, 2));
    };
    const toCamelCase = (str, seperator = '_') => {
        const splitted = str.split(seperator);
        const first = splitted[0];
        const rest = splitted.slice(1).map((word) => word.charAt(0).toUpperCase() + word.slice(1));

        return [first, ...rest].join('');
    };
    // eslint-disable-next-line no-promise-executor-return
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, Math.ceil(ms)));

    /* Returns grouped arrays by property */
    const groupArrayByProperty = (arr, property) => arr.reduce((acc, cur) => {
        acc[cur[property]] = [...acc[cur[property]] || [], cur];

        return acc;
    }, {});

    const round = (value, step = 0.05) => {
        const inv = 1.0 / step;
        return Math.round(value * inv) / inv;
    };

    const mapOrder = (array, order, key, key2) => {
        array.sort((a, b) => {
            const A = a[key][key2];
            const B = b[key][key2];

            if (order.indexOf(A) > order.indexOf(B)) {
                return 1;
            }
            return -1;
        });

        return array;
    };

    const parseHTMLString = (text) => text?.replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&apos;/g, "'")
        .replace(/&uuml;/g, 'ü')
        .replace(/&auml;/g, 'ä')
        .replace(/&ouml;/g, 'ö')
        .replace(/&Uuml;/g, 'Ü')
        .replace(/&Auml;/g, 'Ä')
        .replace(/&Ouml;/g, 'Ö')
        .replace(/&szlig;/g, 'ß')
        .replace(/<[^>]*>?/gm, '') || '';

    const getNumberOfDays = (
        year = dayjs().year(),
        month = (dayjs().month() + 1),
    ) => dayjs(`${year}/${month}/01`).daysInMonth();

    const getFirstDayNameOfMonth = (
        year = dayjs().year(),
        month = (dayjs().month() + 1),
    ) => {
        const dayOfWeek = dayjs(`${year}/${month}/01`).day();
        const dayNames = dayjs.weekdays();
        return dayNames[dayOfWeek];
    };

    const getFirstDayOfMonth = (date) => dayjs(date).startOf('month').toDate();

    const getStartAndEndDates = (date) => {
        const inputMonth = dayjs(date).startOf('month');
        const currentMonth = dayjs().startOf('month');

        const startDate = inputMonth.toDate();
        let endDate;
        if (inputMonth.isSame(currentMonth, 'month')) {
            // If the input month is the current month, set the end date to today
            endDate = dayjs().toDate();
        } else {
            // Otherwise, set the end date to the last day of the input month
            endDate = inputMonth.endOf('month').toDate();
        }

        return { startDate, endDate };
    };

    const getLastDayNameOfMonth = (
        year = dayjs().year(),
        month = (dayjs().month() + 1),
    ) => {
        const lastDay = getNumberOfDays(year, month);
        const dayOfWeek = dayjs(`${year}/${month}/${lastDay}`).day();
        const dayNames = dayjs.weekdays();

        return dayNames[dayOfWeek];
    };

    const getMonthName = (
        year = dayjs().year(),
        month = (dayjs().month() + 1),
    ) => {
        const monthOfTheYear = dayjs(`${year}/${month}/01`).month();
        const monthNames = dayjs.monthsShort(monthOfTheYear);
        return monthNames[monthOfTheYear];
    };

    const getDayNames = (isShort = false) => (isShort ? dayjs.weekdaysShort() : dayjs.weekdays());

    const getMonthNames = (isShort = true) => (isShort ? dayjs.monthsShort() : dayjs.months());

    const getTranslation = (key) => {
        const app = useNuxtApp();
        return app.$i18n?.t(key);
    };

    const formatNumberToCurrency = (value, locale = 'de-CH', currency = 'CHF') => {
        let prefix = '';
        let realValue = parseFloat(value);
        const isNegative = realValue < 0;

        if (isNegative) {
            realValue *= -1;
            prefix = '-';
        }

        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
        });

        const formatted = formatter.format(realValue);
        const splitted = formatted.split(' ');
        return `${prefix}${splitted[1]}`;
    };

    const generateColorPalette = (startColor, endColor, numColors) => {
        const hexToRgb = (hex) => {
            const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return rgb ? {
                r: parseInt(rgb[1], 16),
                g: parseInt(rgb[2], 16),
                b: parseInt(rgb[3], 16),
            } : null;
        };

        const interpolate = (start, end, step, maxSteps) => start + (end - start)
            * (step / maxSteps);

        // eslint-disable-next-line no-bitwise
        const rgbToHex = (r, g, b) => `#${((1 << 24) + (Math.round(r) << 16) + (Math.round(g) << 8) + Math.round(b)).toString(16).slice(1)}`;

        const startRgb = hexToRgb(startColor);
        const endRgb = hexToRgb(endColor);
        const colorArray = [];

        for (let i = 0; i < numColors; i += 1) {
            const r = interpolate(startRgb.r, endRgb.r, i, numColors - 1);
            const g = interpolate(startRgb.g, endRgb.g, i, numColors - 1);
            const b = interpolate(startRgb.b, endRgb.b, i, numColors - 1);
            colorArray.push(rgbToHex(r, g, b));
        }

        return colorArray;
    };

    const formatNumberToString = (num, prefix = '0', length = 3) => {
        let numStr = num.toString();

        if (numStr.length < length) {
            while (numStr.length < length) {
                numStr = `${prefix}${numStr}`;
            }
        }

        return numStr;
    };

    return {
        isInternalLink,
        slugify,
        removeGetParams,
        groupArrayByProperty,
        sleep,
        round,
        mapOrder,
        toCamelCase,
        deepObjectLog,
        urlExists,
        parseHTMLString,
        getNumberOfDays,
        getFirstDayNameOfMonth,
        getFirstDayOfMonth,
        getStartAndEndDates,
        getLastDayNameOfMonth,
        getMonthName,
        getMonthNames,
        getDayNames,
        getTranslation,
        formatNumberToCurrency,
        generateColorPalette,
        formatNumberToString,
    };
};
