export default defineStore('ConfirmPopupStore', {
    state: () => ({
        isOpen: false,
        contentTitle: '',
        contentText: '',
        confirmText: '',
        cancelText: '',
        showConfirm: true,
        showCancel: true,
        onCancel: null, // New property to hold onCancel action
        onConfirm: null, // New property to hold onConfirm action
    }),

    actions: {
        openPopup({
            contentTitle,
            contentText,
            confirmText,
            cancelText,
            showConfirm,
            showCancel,
            onCancel,
            onConfirm,
        }) {
            this.isOpen = true;
            this.contentTitle = contentTitle;
            this.contentText = contentText;
            this.confirmText = confirmText;
            this.cancelText = cancelText;
            this.showConfirm = showConfirm;
            this.showCancel = showCancel;
            this.onCancel = onCancel; // Set the onCancel action
            this.onConfirm = onConfirm; // Set the onConfirm action
        },

        resetPopup() {
            this.isOpen = false;
            this.contentTitle = '';
            this.contentText = '';
        },

        closePopup() {
            if (this.onCancel) {
                this.onCancel();
            }
            this.resetPopup();
        },

        confirm() {
            if (this.onConfirm) {
                this.onConfirm();
            }
            this.resetPopup();
        },
    },

    getters: {
        getRenderState(state) {
            return state.isOpen;
        },
    },
});
