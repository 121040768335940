import { default as AnalyticseiXYO2NmF8Meta } from "/vercel/path0/pages/Analytics.vue?macro=true";
import { default as CashAy8CpUzvdaMeta } from "/vercel/path0/pages/Cash.vue?macro=true";
import { default as _91date_933vv54nQiyYMeta } from "/vercel/path0/pages/dashboard/Daily-Balance/[date].vue?macro=true";
import { default as indexRIIpWdUktDMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as Email_45Confirmation5Vijkt589VMeta } from "/vercel/path0/pages/Email-Confirmation.vue?macro=true";
import { default as Forgot_45Password_45SuccessiHKei9iZkdMeta } from "/vercel/path0/pages/Forgot-Password-Success.vue?macro=true";
import { default as Forgot_45PasswordoMpi1otiUmMeta } from "/vercel/path0/pages/Forgot-Password.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as Intercompany_45MovementszDA6iqt09UMeta } from "/vercel/path0/pages/Intercompany-Movements.vue?macro=true";
import { default as LoginGuI4MjCDVDMeta } from "/vercel/path0/pages/Login.vue?macro=true";
import { default as NotificationsPoE3NJ60SvMeta } from "/vercel/path0/pages/Notifications.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as Reset_45PasswordbVIUQB9hd5Meta } from "/vercel/path0/pages/Reset-Password.vue?macro=true";
import { default as index3TgFLdFc0RMeta } from "/vercel/path0/pages/restaurants/[...id]/index.vue?macro=true";
import { default as configrYHBTJQyIPMeta } from "/vercel/path0/pages/restaurants/[...id]/integrations/hellotess/config.vue?macro=true";
import { default as index5imJ3cBQ7UMeta } from "/vercel/path0/pages/restaurants/[...id]/integrations/hellotess/index.vue?macro=true";
import { default as welcomet4hPSZKxRQMeta } from "/vercel/path0/pages/restaurants/[...id]/integrations/hellotess/welcome.vue?macro=true";
import { default as indextznu3Isn5zMeta } from "/vercel/path0/pages/restaurants/[...id]/integrations/wallee/index.vue?macro=true";
import { default as indexkOmdG3yThEMeta } from "/vercel/path0/pages/restaurants/index.vue?macro=true";
import { default as Select_45RestaurantIGHfJylYWSMeta } from "/vercel/path0/pages/Select-Restaurant.vue?macro=true";
import { default as Select_45TenantMkxv7Xb9QaMeta } from "/vercel/path0/pages/Select-Tenant.vue?macro=true";
import { default as SettingsZJDxiGYKWaMeta } from "/vercel/path0/pages/Settings.vue?macro=true";
import { default as SetupPL6i76FGN9Meta } from "/vercel/path0/pages/Setup.vue?macro=true";
import { default as StatusGGuGkPamcGMeta } from "/vercel/path0/pages/Status.vue?macro=true";
import { default as _91_46_46_46userId_93pzPpm2YIpZMeta } from "/vercel/path0/pages/users/[...userId].vue?macro=true";
import { default as indexOXtObJcgq9Meta } from "/vercel/path0/pages/users/index.vue?macro=true";
export default [
  {
    name: "Analytics___de",
    path: "/Analytics",
    meta: AnalyticseiXYO2NmF8Meta || {},
    component: () => import("/vercel/path0/pages/Analytics.vue").then(m => m.default || m)
  },
  {
    name: "Cash___de",
    path: "/Cash",
    meta: CashAy8CpUzvdaMeta || {},
    component: () => import("/vercel/path0/pages/Cash.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-Daily-Balance-date___de",
    path: "/dashboard/Daily-Balance/:date()",
    meta: _91date_933vv54nQiyYMeta || {},
    component: () => import("/vercel/path0/pages/dashboard/Daily-Balance/[date].vue").then(m => m.default || m)
  },
  {
    name: "dashboard___de",
    path: "/dashboard",
    meta: indexRIIpWdUktDMeta || {},
    component: () => import("/vercel/path0/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "Email-Confirmation___de",
    path: "/Email-Confirmation",
    meta: Email_45Confirmation5Vijkt589VMeta || {},
    component: () => import("/vercel/path0/pages/Email-Confirmation.vue").then(m => m.default || m)
  },
  {
    name: "Forgot-Password-Success___de",
    path: "/Forgot-Password-Success",
    meta: Forgot_45Password_45SuccessiHKei9iZkdMeta || {},
    component: () => import("/vercel/path0/pages/Forgot-Password-Success.vue").then(m => m.default || m)
  },
  {
    name: "Forgot-Password___de",
    path: "/Forgot-Password",
    meta: Forgot_45PasswordoMpi1otiUmMeta || {},
    component: () => import("/vercel/path0/pages/Forgot-Password.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "Intercompany-Movements___de",
    path: "/Intercompany-Movements",
    component: () => import("/vercel/path0/pages/Intercompany-Movements.vue").then(m => m.default || m)
  },
  {
    name: "Login___de",
    path: "/Login",
    meta: LoginGuI4MjCDVDMeta || {},
    component: () => import("/vercel/path0/pages/Login.vue").then(m => m.default || m)
  },
  {
    name: "Notifications___de",
    path: "/Notifications",
    component: () => import("/vercel/path0/pages/Notifications.vue").then(m => m.default || m)
  },
  {
    name: "register___de",
    path: "/register",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "Reset-Password___de",
    path: "/Reset-Password",
    meta: Reset_45PasswordbVIUQB9hd5Meta || {},
    component: () => import("/vercel/path0/pages/Reset-Password.vue").then(m => m.default || m)
  },
  {
    name: "restaurants-id___de",
    path: "/restaurants/:id(.*)*",
    meta: index3TgFLdFc0RMeta || {},
    component: () => import("/vercel/path0/pages/restaurants/[...id]/index.vue").then(m => m.default || m)
  },
  {
    name: "restaurants-id-integrations-hellotess-config___de",
    path: "/restaurants/:id(.*)*/integrations/hellotess/config",
    meta: configrYHBTJQyIPMeta || {},
    component: () => import("/vercel/path0/pages/restaurants/[...id]/integrations/hellotess/config.vue").then(m => m.default || m)
  },
  {
    name: "restaurants-id-integrations-hellotess___de",
    path: "/restaurants/:id(.*)*/integrations/hellotess",
    meta: index5imJ3cBQ7UMeta || {},
    component: () => import("/vercel/path0/pages/restaurants/[...id]/integrations/hellotess/index.vue").then(m => m.default || m)
  },
  {
    name: "restaurants-id-integrations-hellotess-welcome___de",
    path: "/restaurants/:id(.*)*/integrations/hellotess/welcome",
    meta: welcomet4hPSZKxRQMeta || {},
    component: () => import("/vercel/path0/pages/restaurants/[...id]/integrations/hellotess/welcome.vue").then(m => m.default || m)
  },
  {
    name: "restaurants-id-integrations-wallee___de",
    path: "/restaurants/:id(.*)*/integrations/wallee",
    meta: indextznu3Isn5zMeta || {},
    component: () => import("/vercel/path0/pages/restaurants/[...id]/integrations/wallee/index.vue").then(m => m.default || m)
  },
  {
    name: "restaurants___de",
    path: "/restaurants",
    meta: indexkOmdG3yThEMeta || {},
    component: () => import("/vercel/path0/pages/restaurants/index.vue").then(m => m.default || m)
  },
  {
    name: "Select-Restaurant___de",
    path: "/Select-Restaurant",
    meta: Select_45RestaurantIGHfJylYWSMeta || {},
    component: () => import("/vercel/path0/pages/Select-Restaurant.vue").then(m => m.default || m)
  },
  {
    name: "Select-Tenant___de",
    path: "/Select-Tenant",
    meta: Select_45TenantMkxv7Xb9QaMeta || {},
    component: () => import("/vercel/path0/pages/Select-Tenant.vue").then(m => m.default || m)
  },
  {
    name: "Settings___de",
    path: "/Settings",
    component: () => import("/vercel/path0/pages/Settings.vue").then(m => m.default || m)
  },
  {
    name: "Setup___de",
    path: "/Setup",
    meta: SetupPL6i76FGN9Meta || {},
    component: () => import("/vercel/path0/pages/Setup.vue").then(m => m.default || m)
  },
  {
    name: "Status___de",
    path: "/Status",
    component: () => import("/vercel/path0/pages/Status.vue").then(m => m.default || m)
  },
  {
    name: "users-userId___de",
    path: "/users/:userId(.*)*",
    meta: _91_46_46_46userId_93pzPpm2YIpZMeta || {},
    component: () => import("/vercel/path0/pages/users/[...userId].vue").then(m => m.default || m)
  },
  {
    name: "users___de",
    path: "/users",
    meta: indexOXtObJcgq9Meta || {},
    component: () => import("/vercel/path0/pages/users/index.vue").then(m => m.default || m)
  }
]