<template>
  <div class="error-layout is-petrol">
    <div class="error-layout__inner">
      <div class="error-layout__column">
        <div class=" error-layout__code">
          {{ errorTitle }}<br>
          {{ error.statusCode }}
        </div>
      </div>

      <div class="error-layout__column">
        <div class="error-layout__content">
          <p class="error-layout__message">
            {{ error.statusCode === 404 ? messageText : error.message }}
          </p>

          <button
            type="button"
            class="button button--primary"
            @click="handleError()"
          >
            {{ backText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

defineProps({
    error: {
        type: Object,
        default: () => {},
    },
});

const { locale } = useI18n();
const localePath = useLocalePath();

const messageText = computed(() => {
    switch (locale.value) {
    case 'en':
        return 'Sorry, this page could not be found.';
    case 'fr-ch':
        return 'Désolé, cette page ne peut être trouvée.';
    case 'it-ch':
        return 'Spiacente, questa pagina non può essere trovata.';
    default:
        return 'Entschuldigen Sie, diese Seite kann nicht gefunden werden.';
    }
});

const errorTitle = computed(() => {
    switch (locale) {
    case 'en':
        return 'Error';
    case 'fr-ch':
        return 'Erreur';
    case 'it-ch':
        return 'Errore';
    default:
        return 'Fehler';
    }
});

const backText = computed(() => {
    switch (locale) {
    case 'en':
        return 'To the homepage';
    case 'fr-ch':
        return 'Retour à la page d\'accueil';
    case 'it-ch':
        return 'Alla pagina principale';
    default:
        return 'Zur Startseite';
    }
});

const handleError = () => {
    const redirectPath = localePath('/login');
    clearError({ redirect: redirectPath });
};
</script>

<style lang="scss">
@import './assets/scss/resets';
@import './assets/scss/root';
@import './assets/scss/transitions';

.error-layout {
    position: relative;
    width: 100%;
    height: 100vh;
}
</style>
