export default defineNuxtPlugin(async (nuxtApp) => {
    // Skip plugin when rendering error page
    if (nuxtApp.payload.error) {
        return {};
    }

    const { data: session, refresh: updateSession } = await useFetch('/api/auth/session');

    const loggedIn = computed(() => !!session.value?.accessToken);

    return {
        provide: {
            auth: {
                loggedIn,
                session,
                updateSession,
            },
        },
    };
});
