import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAccount, LazySvgoAlipay, LazySvgoAmex, LazySvgoAnalytics, LazySvgoApplePay, LazySvgoArrowLeft, LazySvgoBalance, LazySvgoBell, LazySvgoCheck, LazySvgoChevronDown, LazySvgoChevronLeft, LazySvgoChevronRightRed, LazySvgoChevronRight, LazySvgoCloseWhite, LazySvgoClose, LazySvgoCloudDownload, LazySvgoCompareArrows, LazySvgoDanger, LazySvgoDashboard, LazySvgoDate, LazySvgoDebitoren, LazySvgoDinersClub, LazySvgoDiscover, LazySvgoErrorTriangle, LazySvgoGlobalSettings, LazySvgoGpay, LazySvgoHellotessActive, LazySvgoHellotess, LazySvgoJcb, LazySvgoLightspeedActive, LazySvgoLightspeed, LazySvgoLoginLogo, LazySvgoLogo, LazySvgoLogout, LazySvgoMaestro, LazySvgoMastercard, LazySvgoMastercardDebit, LazySvgoNext, LazySvgoNonDigital, LazySvgoPencilRed, LazySvgoPencilWhite, LazySvgoPencil, LazySvgoPlus, LazySvgoPostfinance, LazySvgoPrevious, LazySvgoRedCircleCheck, LazySvgoRedCirclePlus, LazySvgoReveal, LazySvgoSamsungPay, LazySvgoSelectDone, LazySvgoSelectError, LazySvgoSettingsWhite, LazySvgoSettings, LazySvgoSonstige, LazySvgoSuccessCheck, LazySvgoSync, LazySvgoTicket, LazySvgoTrashRed, LazySvgoTrash, LazySvgoTwint, LazySvgoUnionpay, LazySvgoUpload, LazySvgoViewMore, LazySvgoVisa, LazySvgoVisaDebit, LazySvgoVpay, LazySvgoWallee, LazySvgoWechatPay, LazySvgoWorldlineActive, LazySvgoWorldline } from '#components'
const lazyGlobalComponents = [
  ["SvgoAccount", LazySvgoAccount],
["SvgoAlipay", LazySvgoAlipay],
["SvgoAmex", LazySvgoAmex],
["SvgoAnalytics", LazySvgoAnalytics],
["SvgoApplePay", LazySvgoApplePay],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoBalance", LazySvgoBalance],
["SvgoBell", LazySvgoBell],
["SvgoCheck", LazySvgoCheck],
["SvgoChevronDown", LazySvgoChevronDown],
["SvgoChevronLeft", LazySvgoChevronLeft],
["SvgoChevronRightRed", LazySvgoChevronRightRed],
["SvgoChevronRight", LazySvgoChevronRight],
["SvgoCloseWhite", LazySvgoCloseWhite],
["SvgoClose", LazySvgoClose],
["SvgoCloudDownload", LazySvgoCloudDownload],
["SvgoCompareArrows", LazySvgoCompareArrows],
["SvgoDanger", LazySvgoDanger],
["SvgoDashboard", LazySvgoDashboard],
["SvgoDate", LazySvgoDate],
["SvgoDebitoren", LazySvgoDebitoren],
["SvgoDinersClub", LazySvgoDinersClub],
["SvgoDiscover", LazySvgoDiscover],
["SvgoErrorTriangle", LazySvgoErrorTriangle],
["SvgoGlobalSettings", LazySvgoGlobalSettings],
["SvgoGpay", LazySvgoGpay],
["SvgoHellotessActive", LazySvgoHellotessActive],
["SvgoHellotess", LazySvgoHellotess],
["SvgoJcb", LazySvgoJcb],
["SvgoLightspeedActive", LazySvgoLightspeedActive],
["SvgoLightspeed", LazySvgoLightspeed],
["SvgoLoginLogo", LazySvgoLoginLogo],
["SvgoLogo", LazySvgoLogo],
["SvgoLogout", LazySvgoLogout],
["SvgoMaestro", LazySvgoMaestro],
["SvgoMastercard", LazySvgoMastercard],
["SvgoMastercardDebit", LazySvgoMastercardDebit],
["SvgoNext", LazySvgoNext],
["SvgoNonDigital", LazySvgoNonDigital],
["SvgoPencilRed", LazySvgoPencilRed],
["SvgoPencilWhite", LazySvgoPencilWhite],
["SvgoPencil", LazySvgoPencil],
["SvgoPlus", LazySvgoPlus],
["SvgoPostfinance", LazySvgoPostfinance],
["SvgoPrevious", LazySvgoPrevious],
["SvgoRedCircleCheck", LazySvgoRedCircleCheck],
["SvgoRedCirclePlus", LazySvgoRedCirclePlus],
["SvgoReveal", LazySvgoReveal],
["SvgoSamsungPay", LazySvgoSamsungPay],
["SvgoSelectDone", LazySvgoSelectDone],
["SvgoSelectError", LazySvgoSelectError],
["SvgoSettingsWhite", LazySvgoSettingsWhite],
["SvgoSettings", LazySvgoSettings],
["SvgoSonstige", LazySvgoSonstige],
["SvgoSuccessCheck", LazySvgoSuccessCheck],
["SvgoSync", LazySvgoSync],
["SvgoTicket", LazySvgoTicket],
["SvgoTrashRed", LazySvgoTrashRed],
["SvgoTrash", LazySvgoTrash],
["SvgoTwint", LazySvgoTwint],
["SvgoUnionpay", LazySvgoUnionpay],
["SvgoUpload", LazySvgoUpload],
["SvgoViewMore", LazySvgoViewMore],
["SvgoVisa", LazySvgoVisa],
["SvgoVisaDebit", LazySvgoVisaDebit],
["SvgoVpay", LazySvgoVpay],
["SvgoWallee", LazySvgoWallee],
["SvgoWechatPay", LazySvgoWechatPay],
["SvgoWorldlineActive", LazySvgoWorldlineActive],
["SvgoWorldline", LazySvgoWorldline],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
